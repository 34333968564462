<template>
    <div class="consultingManagement">
        <ds-header title="企服活动列表"></ds-header>
        <a-form layout="inline" style="margin-bottom: 10px">
            <a-form-item label="活动标题：">
                <a-input v-model.trim="searchForm.activityTitle" placeholder="请输入专场名称搜索"></a-input>
            </a-form-item>
            <a-form-item label="发布状态">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px"
                    placeholder="请选择" v-model="searchForm.publishStatus">
                    <a-select-option value=""> 全部状态 </a-select-option>
                    <a-select-option v-for="item in stateList" :key="item.id" :value="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="适用项目">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px"
                    placeholder="请选择" v-model="searchForm.projectId">
                    <a-select-option value=""> 全部项目 </a-select-option>
                    <a-select-option v-for="item in projectIdList" :key="item.projectId" :value="item.projectId">
                        {{ item.projectName }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="活动类型">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px"
                    placeholder="请选择" v-model="searchForm.activityTypeId">
                    <a-select-option value=""> 全部活动类型 </a-select-option>
                    <a-select-option v-for="item in activityTypeList" :key="item.activityTypeId"
                        :value="item.activityTypeId">
                        {{ item.activityTypeName }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="创建时间">
                <a-range-picker :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                    :placeholder="['开始时间', '结束时间']" v-model="creatTime" format="YYYY-MM-DD" />
            </a-form-item>
            <a-form-item label="活动时间">
                <a-range-picker :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                    :placeholder="['开始时间', '结束时间']" v-model="activeTime" format="YYYY-MM-DD" />
            </a-form-item>
            <!-- <a-form-item label="适用企业">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px"
                    placeholder="请选择" v-model="searchForm.enterpriseTypeId">
                    <a-select-option value=""> 全部状态 </a-select-option>
                    <a-select-option v-for="item in enterpriseTypeIdList" :key="item.enterprise_type_id"
                        :value="item.enterprise_type_id">
                        {{ item.enterprise_type_name }}
                    </a-select-option>
                </a-select>
            </a-form-item> -->
            <span>
                <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
                <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
            </span>
        </a-form>
        <div style="margin: 10px 0">
            <a-button type="primary" @click="addInfo()"> <a-icon type="plus" /> 新建活动 </a-button>
        </div>
        <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.tableData"
            :pagination="table.pagination.total ? table.pagination : false" @change="pageChange" bordered
            :scroll="{ x: 1500 }">
            <span slot="action" slot-scope="text, record" class="action">
                <a-button type="link" @click="GET_LIST_PUBLISH(record, '1')" v-if="record.publishStatus === PublishStatusEnum.NOT_PUBLISH"
                    class="btn">
                    发布
                </a-button>
                <a-button type="link" @click="GET_LIST_PUBLISH(record, '2')"
                    v-if="record.publishStatus === PublishStatusEnum.ALREADY_PUBLISH && (record.activityStatus === ActivityStatusEnum.NOT_START || record.activityStatus === ActivityStatusEnum.UNDERWAY)" class="btn"> 取消发布
                </a-button>
                <a-button type="link" @click="toDetail(record)"> 查看 </a-button>
                <a-button type="link" @click="edit(record)" v-if="record.publishStatus === PublishStatusEnum.NOT_PUBLISH && record.activityStatus === ActivityStatusEnum.NOT_START" class="btn" >
                    编辑
                </a-button>
                <a-button type="link" @click="exportEntryBut(record,1)" v-if="record.isApply === ApplyStatusEnum.YES"  class="btn"> 导出报名信息 </a-button>
                <a-button type="link" @click="exportEntryBut(record,2)" v-if="record.participationWay === ParticipationWayEnum.ONLINE" class="btn"> 导出线上签到信息 </a-button>
                <a-button type="link" @click="reuseBut(record)" class="btn"> 信息复用 </a-button>
            </span>
            <div slot="activityTitle" slot-scope="text, record">
                <a-tooltip>
                    <template slot="title">
                        <span>{{ record.activityTitle }}</span>
                    </template>
                    <div class="line">
                        {{ record.activityTitle }}
                    </div>
                </a-tooltip>
            </div>
        </a-table>
        <!-- 导出报名信息 -->
        <a-modal title="选择导出文件" :visible="exportEntryVisible"   @ok="downEntryFile"
            @cancel="exportEntryVisible=false">
            <div class="exportEntry">
                <a-checkbox-group v-model="plainVal">
                    <a-checkbox v-for="(item,index) in plainOptions"  :value="item.value" :key="index">{{ item.label }}</a-checkbox>
                </a-checkbox-group>
            </div>
        </a-modal>
        <!-- 发布取消发布对话框 -->
        <a-modal :title="title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
            @cancel="handleCancel">
            <p v-if="publishStatus === '1'" style="font-weight: 600;text-align: center;">活动发布后，用户将可查看该活动，确定要发布此活动吗？</p>
            <p v-if="publishStatus === '2'" style="font-weight: 600;text-align: center;">
                活动取消发布后，用户将无法查看该活动，且活动无法再重新发布，确定要发布此活动吗？</p>
        </a-modal>
        <!-- 发布取消发布对话框 -->
        <a-modal title="取消发布" :bodyStyle="{ width: '800px' }" :visible="visible2" :confirm-loading="confirmLoading"
            @ok="handleOk2" @cancel="handleCancel2">
            <p>该活动已有用户报名，若要取消发布，请填写‘活动取消系统提示’！</p>


            <div style="display: flex;">
                <span style="color:red;margin-right: 5px;">*</span>
                <a-textarea v-model="cancelReason" placeholder="请输入活动取消系统提示内容" :maxLength="50"
                    :auto-size="{ minRows: 3, maxRows: 5 }" />
            </div>
            <p style="text-align: right;">{{ cancelReason.length }}/50</p>
            <p>
                注意：点击确认后，您填写的‘活动取消系统提示’将会被系统推送给已报名用户，且已取消发布的活动无法再重新发布，请确认后再点击确定！
            </p>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import * as api from "../../../api/enterpriseServiceActivitiesManagement";
import { PublishStatusEnum, ActivityStatusEnum, ApplyStatusEnum, ParticipationWayEnum } from '../../cycleActivities/cycleList/help'
export default {
    data() {
        return {
            visible: false,
            visible2: false,
            exportEntryVisible:false,
            confirmLoading: false,
            // 搜索条件
            searchForm: {
                // 活动标题
                activityTitle: "",
                projectId: "",// 项目id
                activityTypeId: "",//活动类型id
                // enterpriseTypeId: "",//企业类型id
                publishStatus: "",// 状态
            },
            creatTime:[],//创建时间
            activeTime:[],//活动时间
            projectIdList: [],
            activityTypeList: [],
            plainOptions:[],//报名信息
            plainVal:[],//报名
            exportType:0,//1 导出报名信息 2导出线上签到信息
            enterpriseTypeIdList: [],
            stateList: [{ id: 0, name: '未发布' }, { id: 1, name: '已发布' }, { id: 2, name: '取消发布' }],
            table: {
                columns: [
                    {
                        title: "活动标题",
                        dataIndex: "activityTitle",
                        key: "activityTitle",
                        width: 150,
                        fixed: 'left',
                        scopedSlots: { customRender: "activityTitle" },
                    },
                    {
                        title: "活动类型",
                        dataIndex: "activityTypeName",
                        key: "activityTypeName",
                        width: 100,
                    },
                    {
                        title: "活动时间",
                        key: "startTimeStr",
                        width: 157,
                        customRender(text) {
                            return text.startTimeStr + ' ~ ' + text.endTimeStr
                        },
                    },
                    {
                        title: "活动状态",
                        dataIndex: "activityStatusStr",
                        key: "activityStatusStr",
                        width: 100,
                    },
                    {
                        title: "适用项目",
                        dataIndex: "projectStr",
                        key: "projectStr",
                        width: 190,
                    },
                    {
                        title: "是否发放问卷",
                        dataIndex: "isQuestionnaire",
                        key: "isQuestionnaire",
                        width: 120,
                        customRender(text) {
                            console.log(text);
                            return !text ? '是' : "否";
                        },
                    },
                    {
                        title: "链接地址（活动详情页)",
                        dataIndex: "specialLink",
                        key: "specialLink",
                        width: 280,
                    },
                    {
                        title: "当前报名人数",
                        key: "count",
                        dataIndex: "count",
                        width: "120px",
                    },
                    {
                        title: "发布状态",
                        key: "publishStatus",
                        dataIndex: "publishStatus",
                        width: "120px",
                        customRender(text) {
                            let str = ''
                            switch (text) {
                                case 0:
                                    str = '未发布'
                                    break;
                                case 1:
                                    str = '已发布'
                                    break;

                                default:
                                    str = '取消发布'
                                    break;
                            }
                            return str;
                        },
                    },
                    {
                        title: "创建时间",
                        key: "createDate",
                        dataIndex: "createDate",
                        width: 180,
                    },
                    {
                        title: "操作",
                        key: "action",
                        width: "280px",
                        className: "action-ql-list",
                        scopedSlots: { customRender: "action" },
                        fixed: 'right',
                    },
                ],
                tableData: [],
                pagination: {
                    pageSizeOptions: ["10", "20", "30", "50", "100"],
                    showSizeChanger: true,
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showQuickJumper: true,
                    showTotal: (total) => {
                        return `共 ${total} 条`;
                    },
                },
            },
            activityId: '',
            publishStatus: '',
            title: '',
            cancelReason: '', //取消原因
            PublishStatusEnum,
            ActivityStatusEnum,
            ApplyStatusEnum,
            ParticipationWayEnum,
        }
    },
    created() {
        
        this.FIND_ENTERPRIES_TYPE()
        this.GET_LISTA_CTIVITYLIST()
        // this.getEnterpriseType()
    },
    mounted(){
        this.getList()
        let handleResize = () => {
        let width = document.getElementsByClassName('consultingManagement')[0].clientWidth;
       console.log(width);
       
        if (width > 1800) {
            this.table.columns[this.table.columns.length - 1].fixed = '';
            this.table.columns[0].fixed = '';
        } else {
            this.table.columns[this.table.columns.length - 1].fixed = 'right';
            this.table.columns[0].fixed = 'left';
        }
        }
        window.addEventListener('resize', handleResize);
        handleResize()
    },
    methods: {
        moment,
        resetSearchForm() {
            this.searchForm = {
                activityTitle: "",   // 活动标题
                projectId: "",// 项目id
                activityTypeId: "",//活动类型id
                enterpriseTypeId: "",//企业类型id
                publishStatus: "",// 状态
            };
            this.creatTime=[]
            this.activeTime=[]
            this.table.pagination.current = 1
            this.table.pagination.pageSize = 10
            this.getList()
        },
        search() {
            this.table.pagination.current = 1
            this.table.pagination.pageSize = 10
            this.getList()
        },
        addInfo() {
            this.$router.push({
                path: "/enterpriseServiceActivitiesManagement/add",
            });
        },
        // 编辑
        edit(record) {
            console.log(record);
            this.$router.push({
                path: "/enterpriseServiceActivitiesManagement/add",
                query: {
                    id: record.activityId,
                },
            });
        },
        //信息复用
        reuseBut(record){
            this.$router.push({
                path: "/enterpriseServiceActivitiesManagement/add",
                query: {
                    id: record.activityId,
                    reuseType:1
                },
            });
        },
        getList() {
            const data = {
                pageIndex: this.table.pagination.current,
                pageSize: this.table.pagination.pageSize,
                ...this.searchForm,
            };
            if(this.creatTime.length){
                data.creatTimeStart=this.moment(this.creatTime[0]).format('YYYY-MM-DD')
                data.creatTimeEnd=this.moment(this.creatTime[1]).format('YYYY-MM-DD')
            }else{
                data.creatTimeStart=''
                data.creatTimeEnd=''
            }
            if(this.activeTime.length){
                data.activeTimeStart=this.moment(this.activeTime[0]).format('YYYY-MM-DD')
                data.activeTimeEnd=this.moment(this.activeTime[1]).format('YYYY-MM-DD')
            }else{
                data.activeTimeStart=''
                data.activeTimeEnd=''
            }
            api.GET_LIST_ACTIVITY(data).then(res => {
                if (res.code === "200") {
                    const rows = [];
                    // 拼接专题链接
                    if (res.data && res.data.rows && res.data.rows.length > 0) {
                        for (let idx = 0; idx < res.data.rows.length; idx++) {
                            const tempData = {...res.data.rows[idx], specialLink: '-'};
                            // TODO 链接地址待定
                            if (!tempData.isShare) {
                                tempData.specialLink = `${process.env.NODE_ENV === 'production' ? 'https://yuanqu.innoecos.cn/apps/ies/CalenderActivityDetail?activeId=' + tempData.activityId :
                                    'https://yuanqu-sit.innoecos.cn/apps/ies/CalenderActivityDetail?activeId=' + tempData.activityId}`;
                            }
                            rows.push(tempData);
                        }
                    }
                    this.table.tableData = rows;
                    this.table.pagination.total = res.data.total;
                }
            }).catch(error => {
                console.log(error);
            })
            // try {
            //     const data = {
            //         pageIndex: this.table.pagination.current,
            //         pageSize: this.table.pagination.pageSize,
            //         ...this.searchForm,
            //     };
            //     const res = await api.GET_LIST_ACTIVITY(data);
            // if (res.code === "200") {
            //     // 拼接专题链接
            //     if (res.data && res.data.rows && res.data.rows.length > 0) {
            //         for (var idx = 0; idx < res.data.rows.length; idx++) {
            //             // TODO 链接地址待定
            //             if (!res.data.rows[idx].isShare) {
            //                 res.data.rows[idx].specialLink = `${process.env.NODE_ENV === 'production' ? 'https://yuanqu.innoecos.cn/apps/ies/CalenderActivityDetail?id=' + res.data.list[idx].activityId :
            //                     'https://yuanqu-sit.innoecos.cn/apps/ies/CalenderActivityDetail?id=' + res.data.rows[idx].activityId}`;
            //             } else {
            //                 res.data.rows[idx].specialLink = "-";
            //             }
            //         }
            //     }
            //     this.table.tableData = res.data.rows;
            //     if (res.data) {
            //         if (res.data.total) {
            //             this.table.pagination.total = res.data.total;
            //         }
            //     }

            // }
            // } catch (error) {

            // }

        },
        // 切换分页
        pageChange(option) {
            this.table.pagination.current = option.current;
            this.table.pagination.pageSize = option.pageSize;
            this.getList();
        },
        // 详情
        toDetail(record) {
            this.$router.push({
                path: "/enterpriseServiceActivitiesManagement/detail",
                query: {
                    id: record.activityId,
                },
            });
        },
        // 适用项目
        async FIND_ENTERPRIES_TYPE() {
            let data = await api.FINDPROJECT()
            this.projectIdList = data.data
        },
        // 获取活动类型
        async GET_LISTA_CTIVITYLIST() {
            let data = await api.GET_LISTA_CTIVITYLIST()
            if (data.code === '200') {
                this.activityTypeList = data.data
            }
        },
        // 获取企业类型
        // async getEnterpriseType() {
        //     let data = await api.getEnterpriseType()
        //     console.log(data);
        //     if (data.data.code === '200') {
        //         this.enterpriseTypeIdList = data.data.data

        //     }
        // },
        // 下载
        downloadCommon(res) {
            this.downloadCommon(res);
        },
        downloadCommon(res) {
            if ("msSaveOrOpenBlob" in navigator) {
                const url = res.data;
                const blob = new Blob([url], { type: "application/vnd.ms-excel" });
                let name = res.headers["content-disposition"].split("=")[1];
                window.navigator.msSaveOrOpenBlob(blob, decodeURI(name));
                return;
            } else {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                let name = res.headers["content-disposition"].split("=")[1];
                link.setAttribute("download", decodeURI(name));
                document.body.appendChild(link);
                link.click();
            }

        },
        //导出
        exportEntryBut(data,type){
            this.exportType=type
            this.plainOptions=[]
            this.plainVal=[]
            if(data.activityPeriods){
                data.activityPeriods.forEach(element => {  
                let str=this.moment(element.startTime).format('YYYY/MM/DD HH:mm')+' - '+this.moment(element.endTime).format('YYYY/MM/DD HH:mm')
                let obj={ label: str, value: str ,id: element.id,activityTitle:data.activityTitle,disabled:moment().valueOf()<moment(element.startTime).valueOf(), activityId: data.activityId}
                this.plainOptions.push(obj)
                })
            }else{
                let str=this.moment(data.startTime).format('YYYY/MM/DD HH:mm')+' - '+this.moment(data.endTime).format('YYYY/MM/DD HH:mm')
                this.plainOptions=[{label: str, value: str ,id: data.activityId,activityTitle:data.activityTitle,disabled:moment().valueOf()<moment(data.startTime).valueOf(), activityId: data.activityId}]
            }

            this.exportEntryVisible=true
        },
        // 下载名单
        async downEntryFile() {
            if(!this.plainVal.length){
                this.$message.warning('请选择时间段');
                return
            }
            this.plainOptions.forEach(item=>{
                this.plainVal.forEach(element=>{
                    if(element==item.value){
                        this.downFile(item.activityId,item.activityTitle,element)
                    }
                })
            })
        },
        //下载文件
        async downFile(dataList,activityTitle,time){
            if(this.exportType==1){
                let res = await api.GET_LIST_REGISTRANT({activityIds:[dataList]})
                this.exportEntryVisible=false
                const blob = new Blob([res.data]);
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectUrl;
                a.setAttribute("download", "活动报名信息_"+activityTitle+'_'+time+".xlsx");
                a.click();
            }else if(this.exportType==2){
                let res = await api.exportSignInBut({periodIds:[dataList]})
                this.exportEntryVisible=false
                const blob = new Blob([res.data]);
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectUrl;
                a.setAttribute("download", activityTitle+'_'+time+".xlsx");
                a.click();
            }
        },
        // 检验是否能取消/发布
        async GET_LIST_PUBLISH(record, val) {
            let res = await api.GET_LIST_PUBLISH({ activityId: record.activityId, publishStatus: val })
            if (res.code === '200') {
                if (val === '1') {
                    this.title = '发布'
                } else {
                    this.title = '取消发布'
                }
                this.visible = true
                this.activityId = record.activityId;
                this.publishStatus = val
            } else if (res.code === '1000') {
                this.activityId = record.activityId;
                this.visible2 = true
            } else {
                this.$message.error(res.msg);
            }
        },
        // 发布/取消发布
        async SET_LIST_PUBLISH(activityId, val) {

            let res = await api.SET_LIST_PUBLISH({ activityId: activityId, publishStatus: val, cancelReason: this.cancelReason })
            this.visible = false
            this.visible2 = false
            this.confirmLoading = false;
            this.getList()
        },
        handleOk(e) {
            this.confirmLoading = true;
            this.SET_LIST_PUBLISH(this.activityId, this.publishStatus)
        },
        handleCancel(e) {
            console.log('Clicked cancel button');
            this.visible = false;
        },
        handleOk2(e) {
            if (!this.cancelReason) {
                this.$message.warning('取消原因不能为空');
                return
            }
            this.confirmLoading = true;
            this.SET_LIST_PUBLISH(this.activityId, 2)
        },
        handleCancel2(e) {
            console.log('Clicked cancel button');
            this.visible2 = false;
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    pointer-events: auto;
    min-width: 350px;
    display: inline-block;
    left: 50%;
    transform: translate(-50%);
}

.line {
    overflow: hidden; // 超出的文本隐藏
    text-overflow: ellipsis; // 溢出用省略号显示
    display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
    -webkit-line-clamp: 2; // 这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。
    -webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
}
::v-deep .exportEntry .ant-checkbox-group-item{
    margin-top:10px ;
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper{
  margin-left:0
}
.btn {
  padding: 0;
  margin-left: 5px;
}
</style>